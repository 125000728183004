import React, { useState } from 'react'
import { onClient } from './index'
import GdprConsent from '../components/gdpr-consent'
export const gdprContext = React.createContext()

const GdprProvider = ({ children }) => {
  const [gdpr, setGdpr] = useState(
    (onClient() && JSON.parse(localStorage.getItem('gdpr'))) || false
  )

  const acceptGdpr = () => {
    localStorage.setItem('gdpr', JSON.stringify({ answer: true }))
    setGdpr(true)
  }

  return (
    <gdprContext.Provider
      value={{
        gdpr,
        acceptGdpr
      }}
    >
      {children}
      {!gdpr && <GdprConsent />}
    </gdprContext.Provider>
  )
}

export default GdprProvider
