import { createMedia } from "@artsy/fresnel"
export const breakpoints = {
  sm: 0,
  md: 768,
  lg: 1024,
  xl: 1192,
}
const ExampleAppMedia = createMedia({
  breakpoints,
})

// Generate CSS to be injected into the head
export const mediaStyle = ExampleAppMedia.createMediaStyle()
export const { Media, MediaContextProvider } = ExampleAppMedia