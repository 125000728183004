import React from 'react'
import UserProvider from './src/context/user-provider'
import LanguageProvider from './src/context/language-provider'
import ImageProvider from './src/context/image-provider'
import { MediaContextProvider } from './src/helpers/media'
import SectionProvider from './src/context/section-provider'
import ContactProvider from './src/context/contact-provider'
import GdprProvider from './src/context/gdpr-provider'

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === `undefined`) {
    await import(`intersection-observer`)
  }
}

export const wrapRootElement = ({ element }) => (
  <GdprProvider>
    <SectionProvider>
      <MediaContextProvider>
        <LanguageProvider>
          <ImageProvider>
            <ContactProvider>
              <UserProvider>{element}</UserProvider>
            </ContactProvider>
          </ImageProvider>
        </LanguageProvider>
      </MediaContextProvider>
    </SectionProvider>
  </GdprProvider>
)
