import React, { useContext } from 'react'
import { Dialog } from '@material-ui/core'
import Slide from '@material-ui/core/Slide'
import { RichText } from 'prismic-reactjs'
import styled from 'styled-components'
import { contactContext } from '../../context/contact-provider'
import { breakpoints } from '../../helpers/media'
import { useStaticQuery, graphql } from 'gatsby'
import useTranslation from '../../hooks/use-translation'
import CustomRichText from '../../components/custom-rich-text'
import { DEFAULT_COLOR, DEFAULT_GREY_TITLE } from '../../helpers/colors'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})

const Wrapper = styled.div`
  display: block;
  overflow-x: hidden;
  @media only screen and (max-width: ${breakpoints.md}px) {
    max-width: 100%;
  }
`

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  color: #4c4d4f;
`

const MainTitle = styled.h1`
  color: ${DEFAULT_GREY_TITLE};
  width: 100%;
  text-align: left;
  font-size: 22px;
  margin: 40px;
  @media only screen and (min-width: ${breakpoints.md}px) {
    text-align: center;
    font-size: 35px;
  }
`

const ContactContent = styled.div(
  ({ dark }) => `
  display: flex;
  flex-direction: column;
  margin: 35px;
  width: 400px;
  background-color: ${dark ? DEFAULT_COLOR : ''};
`
)

const Title = styled.h6(
  ({ dark }) => `
  font-size: 16px;
  font-weight: bold;
  margin: 5px 0px;
  color: ${dark ? '#FFF' : '#4c4d4f'};
  @media only screen and (min-width: ${breakpoints.md}px) {
    font-size: 22px;
  }
`
)

const FreeTextWrapper = styled.div`
  width: 100%;
  margin-bottom: 30px;
  text-align: center;
`

const Text = styled.div(
  ({ dark }) => `
  & h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  a,
  p {
    font-size: 12px;
    color: ${dark ? '#FFF' : '#4c4d4f'};
    font-weight: lighter;
  }
  @media only screen and (min-width: ${breakpoints.md}px) {
    & h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    a,
    p {
      font-size: 21px;
    }
  }
`
)

const ContactDialog = () => {
  const { isOpen, close } = useContext(contactContext)
  const t = useTranslation()
  const data = useStaticQuery(graphql`
    query ContactQuery {
      prismic {
        allFooters {
          edges {
            node {
              dialog_free_text
              dialog_content {
                title
                text
              }
              footer_content {
                title
                text
              }
            }
          }
        }
      }
    }
  `)
  return (
    <Dialog
      open={!!isOpen}
      onClose={close}
      maxWidth='md'
      fullWidth
      TransitionComponent={Transition}
    >
      {!!isOpen && (
        <Wrapper>
          <InnerWrapper>
            <MainTitle>{t`Contact information`}</MainTitle>
            <FreeTextWrapper>
              <CustomRichText
                richText={
                  data.prismic.allFooters.edges[0].node.dialog_free_text
                }
              />
            </FreeTextWrapper>
            <div
              style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                flexWrap: 'wrap',
                width: '100%',
                backgroundColor: `${DEFAULT_COLOR}`
              }}
            >
              {data.prismic.allFooters.edges[0].node.footer_content
                .concat(data.prismic.allFooters.edges[0].node.dialog_content)
                .map((content, index) => (
                  <ContactContent dark={true}>
                    <Title dark={true}>{RichText.asText(content.title)}</Title>
                    <Text dark={true}>
                      <CustomRichText richText={content.text} />
                    </Text>
                  </ContactContent>
                ))}
            </div>
          </InnerWrapper>
        </Wrapper>
      )}
    </Dialog>
  )
}

export default ContactDialog
