import React, { createContext, useState } from 'react'
import FullScreenImage from '../components/full-screen-image'
export const imageContext = createContext()

const ImageProvider = ({ children }) => {
  const [image, setImage] = useState()
  const [imageSrc, setImageSrc] = useState()

  const close = () => {
    setImage()
    setImageSrc()
  }

  return (
    <imageContext.Provider
      value={{ image, setImage, imageSrc, setImageSrc, close }}
    >
      {children}
      <FullScreenImage />
    </imageContext.Provider>
  )
}

export default ImageProvider
