import React, { createContext, useState } from 'react'
import ContactDialog from '../components/contact-dialog'
export const contactContext = createContext()

const ContactProvider = ({ children }) => {
  const [isOpen, setIsOpen] = useState()

  const openDialog = () => {
    setIsOpen(true)
  }
  const close = () => {
    setIsOpen(false)
  }

  return (
    <contactContext.Provider value={{ isOpen, openDialog, close }}>
      {children}
      <ContactDialog />
    </contactContext.Provider>
  )
}

export default ContactProvider
