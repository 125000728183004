export default {
  Login: 'Login',
  Logout: 'Logout',
  'Logged in as': 'Logged in as',
  'New Student': 'New Student',
  Companies: 'Companies',
  'Alumni plural': 'Alumni',
  Alumni: 'Alumni',
  Committees: 'Committees',
  Students: 'Students',
  Calendar: 'Calendar',
  Newsfeed: 'Newsfeed',
  Filter: 'Filter',
  'Useful links': 'Useful links',
  Book: 'Book',
  Schedule: 'Schedule',
  'Read more': 'Read more',
  Partners: 'Partners',
  'Main Sponsor': 'Main Sponsor',
  'Make sure to close the browser completely to logout from all sessions':
    'Make sure to close the browser completely to logout from all sessions',
  'Successfully logged out': 'Successfully logged out',
  'Something went wrong': 'Something went wrong',
  'Try again': 'Try again',
  'The Education': 'The Education',
  Apply: 'Apply',
  'as a mentor': 'as a mentor',
  'as an adept': 'as an adept',
  'Apply here to become': 'Apply here to become',
  'The Program': 'The Program',
  'Studying at Chalmers': 'Studying at Chalmers',
  'The City for Students': 'The City for Students',
  'Student life at Chalmers': 'Student life at Chalmers',
  'Chalmers-slang': 'Chalmers-slang',
  Interviews: 'Interviews',
  'You have to login to view this page': 'You have to login to view this page',
  Contact: 'Contact',
  'Contact information': 'Contact information',
  'Important information': 'Important information',
  'Video introduction': 'Video introduction',
  'Important documents': 'Important documents',
  'Hide menu': 'Hide menu',
  'Show menu' : 'Show menu'
}
