import React, { createContext, useEffect, useState } from 'react'
import { availableLanguages } from './languages'
import { onClient } from '../index'
export const languageContext = createContext()


const LanguageProvider = ({ children }) => {
  const [selectedLanguage, setSelectedLanguage] = useState(
    getStoredLanguage()
  )
  const [languageOption, setLanguageOption] = useState(
    availableLanguages[Math.abs(selectedLanguage.id-1)]
  )
  useEffect(() => {
    if (onClient() && selectedLanguage) {
      localStorage.setItem('languageId', selectedLanguage.id)
      setLanguageOption(availableLanguages[Math.abs(selectedLanguage.id-1)])
    }
  }, [selectedLanguage])
  const toggle = () => {
    setSelectedLanguage(availableLanguages[Math.abs(selectedLanguage.id-1)])
  }

  const t = (text) => {
    return (selectedLanguage && selectedLanguage.locales[text]) || text
  }
  return (
    <languageContext.Provider value={{ selectedLanguage, toggle, languageOption, t}}>
      {children}
    </languageContext.Provider>
  )
}

const getStoredLanguage = () => {
  const storedLanguageId = onClient() && localStorage.getItem('languageId')
  if (!storedLanguageId) {
    return availableLanguages[0] //default is set to swedish
  }
  return availableLanguages[storedLanguageId]
}

export default LanguageProvider