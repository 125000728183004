import React, { useContext } from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { imageContext } from '../../context/image-provider'

const ImgWrapper = styled.div`
  width: 100%;
  height: 100%;
  &:hover {
    cursor: pointer;
    opacity: 0.9;
  }
`
const ClickableSrcImg = props => {
  const { setImageSrc } = useContext(imageContext)
  return (
    props.src ? (
      <ImgWrapper onClick={() => setImageSrc(props.src)}>
        <img {...props} />
      </ImgWrapper>
    ) : ''
  )
}

export default ClickableSrcImg
