import React from 'react'
import ClickableSrcImg from '../clickable-src-img/index'
import { RichText, Elements } from 'prismic-reactjs'

const CustomRichText = ({ richText }) => {
  return <RichText render={richText} htmlSerializer={htmlSerializer} />
}

export const htmlSerializer = function (type, element, content, children, key) {
  var props = {}
  switch (type) {
    // Don't wrap images in a <p> tag
    case Elements.image:
      props = { src: element.url, alt: element.alt || '' }
      return <ClickableSrcImg {...props} />

    default:
      return null
  }
}

export default CustomRichText
