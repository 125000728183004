import React from 'react'
import { Button } from '@material-ui/core'

const buttonStyle = {
  alignSelf: 'flex-end',
  borderRadius: 20,
}
const Badge = ({
  variant = 'contained',
  fontSize,
  width,
  height,
  color,
  backgroundColor,
  children,
  href,
  marginLeft,
  marginTop,
  onClick,
  padding,
  target
}) => {
  return (
    <Button
      disableElevation
      target={target}
      variant={variant}
      href={href ? href : ''}
      onClick={onClick}
      style={{
        ...buttonStyle,
        fontSize: fontSize,
        width: width || '150px',
        height: height || '35px',
        backgroundColor: backgroundColor || 'white',
        color: color || 'black',
        marginLeft: marginLeft || '10px',
        marginTop: marginTop,
        padding: padding
      }}
    >
      {children}
    </Button>
  )
}

export default Badge
