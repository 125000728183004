import React, { useContext } from 'react'
import styled from 'styled-components'
import { gdprContext } from '../../context/gdpr-provider'
import { Icon } from '@material-ui/core'
import Badge from '../badge'

const Wrapper = styled.div`
  position: fixed;
  bottom: 0px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  background-color: white;
  box-shadow: 0px 0px 4px 4px #00000042;
  width: 100vw;
  padding: 30px;
  pointer-events: default;
  z-index: 1000000;
  animation-name: opacityInSlow;
  animation-duration: 3.5s;
  animation-iteration-count: 1;
`

const Text = styled.h4`
  margin-left: 10px;
`
const GdprConsent = () => {
  const { acceptGdpr } = useContext(gdprContext)
  return (
      <Wrapper>
        <Icon fontSize='large'>info</Icon>
        <Text>
          Vi använder cookies för att göra din upplevelse så bra som möjligt.
        </Text>
        <Badge
          width='auto'
          onClick={acceptGdpr}
          fontSize='14px'
          marginLeft='35px'
          height="55px"
          backgroundColor='rgb(12, 167, 71)'
          color='white'
        >
          Ok, jag förstår
        </Badge>
      </Wrapper>
  )
}

export default GdprConsent
