export default {
  Login: 'Logga in',
  Logout: 'Logga ut',
  'Logged in as': 'Inloggad som',
  'New Student': 'Sökande',
  Companies: 'Företag',
  'Alumni plural': 'Alumner',
  Alumni: 'Alumn',
  Committees: 'Kommittéer',
  Students: 'Studerande',
  Calendar: 'Kalender',
  Newsfeed: 'Nyhetsflöde',
  Filter: 'Filtrering',
  'Useful links': 'Nyttiga länkar',
  Book: 'Boka',
  Schedule: 'Schema',
  'Read more': 'Läs mer',
  Partners: 'Samarbetspartners',
  'Main Sponsor': 'Huvudsponsor',
  'Make sure to close the browser completely to logout from all sessions':
    'Tänk på att stänga ner webbläsaren helt och hållet för att säkerställa att utloggningen verkar',
  'Successfully logged out': 'Utloggningen lyckades',
  'Something went wrong': 'Något gick fel',
  'Try again': 'Prova igen',
  'The Education': 'Utbildningen',
  Apply: 'Ansök',
  'a mentor': 'mentor',
  'an adept': 'adept',
  'Apply here to become': 'Ansök här för att bli en',
  'The Program': 'Programmet på I',
  'Studying at Chalmers': 'Plugga på Chalmers',
  'The City for Students': 'Studentstaden',
  'Student life at Chalmers': 'Studentlivet på Chalmers',
  'Chalmers-slang': 'Chalmers-slang',
  Interviews: 'Intervjuer',
  'You have to login to view this page':
    'Du måste logga in för att få tillgång till den här sidan',
  Contact: 'Kontakt',
  'Contact information': 'Kontaktinformation',
  'Important information': 'Information',
  'Video introduction': 'Presentationsfilm',
  'Important documents': 'Viktiga dokument',
  'Hide menu': 'Göm meny',
  'Show menu': 'Visa meny'
}
