import React, { useEffect, useState } from 'react'
import socketIOClient from 'socket.io-client'
import { navigate } from 'gatsby'
import { onClient } from './index'
export const userContext = React.createContext()

const ENDPOINT = 'https://sso.i-chalmers.se'
const API_ENDPOINT = `${ENDPOINT}/api`

const UserProvider = ({ children }) => {
  const [user, setUser] = useState()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()
  const [loginUrl, setLoginUrl] = useState()

  useEffect(() => {
    const storedUser =
      (onClient() && JSON.parse(localStorage.getItem('user'))) || ''
    async function verifyUser (storedUser) {
      setLoading(true)
      const response = await fetch(`${API_ENDPOINT}/verifyUser`, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          'x-access-token': storedUser.accessToken
        }
      })
      const json = await response.json()
      if (json.success && !json.error) {
        setUser(storedUser)
        setLoading(false)
      } else {
        logout()
      }
    }
    storedUser && verifyUser(storedUser)
  }, [])

  const initializeLogin = async () => {
    setError()
    setLoading(true)
    const { id, loginUrl } = await postLoginRequest()
    if (!loginUrl || !id) {
      setError('Login not available..')
      setLoading(false)
      return ''
    }
    openSocketConnection(id)
    setLoginUrl(loginUrl)
    return loginUrl
  }

  const openSocketConnection = id => {
    const socket = socketIOClient('https://sso.i-chalmers.se')
    socket.emit('ToAPI', { id })
    socket.on('FromAPI', data => {
      if (data.error) {
        setLoading(false)
        setError(data.error)
      }
      if (data.user) {
        setLoading(false)
        setUser(data.user)
        onClient() && localStorage.setItem('user', JSON.stringify(data.user))
        navigate('/app/studerande')
      }
    })
  }

  const postLoginRequest = async () => {
    const response = await fetch(`${API_ENDPOINT}/initializeSignin`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      }
    })
    const json = await response.json()
    if (json.loginUrl && json.id) {
      return { id: json.id, loginUrl: json.loginUrl }
    }
    return ''
  }

  const cancelLogin = () => {
    setLoading(false)
    setLoginUrl()
    setError()
    setUser()
  }

  const logout = () => {
    onClient() && localStorage.removeItem('user')
    setLoading(false)
    setLoginUrl()
    setError()
    setUser()
  }

  return (
    <userContext.Provider
      value={{
        user,
        loginUrl,
        loading,
        initializeLogin,
        error,
        logout,
        cancelLogin
      }}
    >
      {children}
    </userContext.Provider>
  )
}

export default UserProvider
