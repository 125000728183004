import React, { useContext } from 'react'
import { Dialog, IconButton, Icon } from '@material-ui/core'
import Img from 'gatsby-image'
import { imageContext } from '../../context/image-provider'
import Slide from '@material-ui/core/Slide'

const Transition = React.forwardRef(function Transition (props, ref) {
  return <Slide direction='up' ref={ref} {...props} />
})
const FullScreenImage = () => {
  const { image, imageSrc, close } = useContext(imageContext)
  return (
    <Dialog
      open={!!image || !!imageSrc}
      onClose={close}
      maxWidth='lg'
      fullWidth
      TransitionComponent={Transition}
      keepMounted
      style={{ zIndex: 1400 }}
    >
      {image && <Img imgStyle={{ objectFit: 'contain' }} fluid={image} />}
      {imageSrc && (
        <img
          style={{ objectFit: 'contain' }}
          src={imageSrc}
          alt='full screen image'
        />
      )}
      <IconButton
        style={{ position: 'absolute', right: '10px', top: '10px' }}
        onClick={close}
      >
        <Icon fontSize='large'>close</Icon>
      </IconButton>
    </Dialog>
  )
}

export default FullScreenImage
