import React, { createContext, useState } from 'react'
export const sectionContext = createContext()

const SectionProvider = ({ children }) => {
  const [refs, setRefs] = useState({})

  const addRef = (id, ref) => {
    setRefs((prevState) => ({ ...prevState, [id]: ref }))
  }

  const removeRef = id => {
    setRefs((prevState) => ({ ...prevState, [id]: undefined }))
  }

  return (
    <sectionContext.Provider value={{ refs, addRef, removeRef }}>
      {children}
    </sectionContext.Provider>
  )
}

export default SectionProvider
