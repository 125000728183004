// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js": () => import("./../node_modules/gatsby-source-prismic-graphql/components/PreviewPage.js" /* webpackChunkName: "component---node-modules-gatsby-source-prismic-graphql-components-preview-page-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-alumner-js": () => import("./../src/pages/alumner.js" /* webpackChunkName: "component---src-pages-alumner-js" */),
  "component---src-pages-close-js": () => import("./../src/pages/close.js" /* webpackChunkName: "component---src-pages-close-js" */),
  "component---src-pages-foretag-js": () => import("./../src/pages/foretag.js" /* webpackChunkName: "component---src-pages-foretag-js" */),
  "component---src-pages-golden-i-js": () => import("./../src/pages/golden-i.js" /* webpackChunkName: "component---src-pages-golden-i-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-logout-js": () => import("./../src/pages/logout.js" /* webpackChunkName: "component---src-pages-logout-js" */),
  "component---src-pages-page-2-js": () => import("./../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-sokande-js": () => import("./../src/pages/sokande.js" /* webpackChunkName: "component---src-pages-sokande-js" */),
  "component---src-views-app-index-js": () => import("./../src/views/app/index.js" /* webpackChunkName: "component---src-views-app-index-js" */),
  "component---src-views-kommitteer-index-js": () => import("./../src/views/kommitteer/index.js" /* webpackChunkName: "component---src-views-kommitteer-index-js" */),
  "component---src-views-sektionen-index-js": () => import("./../src/views/sektionen/index.js" /* webpackChunkName: "component---src-views-sektionen-index-js" */)
}

