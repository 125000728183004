const default_rgb = '161, 84, 161'
export const DEFAULT_COLOR = `rgba(${default_rgb}, 1)`
export const DEFAULT_COLOR_RGBA = (opacity) => `rgba(${default_rgb}, ${opacity})`

//const default_bg_rgb  = '186,104,200'
const default_bg_rgb  = '220, 179, 227'
export const DEFAULT_BACKGROUND = `rgba(${default_bg_rgb}, 1)`
export const DEFAULT_BACKGROUND_RGBA = (opacity) => `rgba(${default_bg_rgb}, ${opacity})`

export const DEFAULT_GREY = "#4C4D4FE5"

export const DEFAULT_GREY_TITLE = "#52555D"